import React from 'react'
import beforeImg from '../designs/dist/img/before-img.png'
import afterImg from '../designs/dist/img/after-img.png'
import styled from 'styled-components'
import Layout from '../components/Layout'

class CaseStudyPage extends React.Component {
  state = {
    menuVisible: false,
    width: 0,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange)
    this.setState({ width: window.innerWidth })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }

  toggleMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible })
  }

  render() {
    return (
      <Layout menuVisible={this.state.menuVisible} toggleMenu={this.toggleMenu}>
        <section
          className="section-data"
          // ref={this.caseStudy}
          id={'caseStudy'}
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              {this.state.width > 991 && (
                <div className="col-12 col-lg-5">
                  <img src={beforeImg} alt="before-img" />
                  <img src={afterImg} alt="after-img" />
                </div>
              )}
              <div className="col-12 col-lg-6 data-holder mt-3 mt-lg-0">
                <h2 className="h1 section-title blue section-data-header">
                  <span>Scaling a</span>
                  <br />
                  <span className="dark-effect">data-heavy</span>
                  <br />
                  <span>node.js</span>
                  <br />
                  <span className="dark-effect">application</span>
                </h2>
              </div>
              {this.state.width <= 991 && (
                <div className="col-12 col-lg-6">
                  <img src={beforeImg} alt="before-img" />
                  <img src={afterImg} alt="after-img" />
                </div>
              )}
            </div>
          </div>
          <CaseStudyDetails className={'container'}>
            <div className={'section'}>
              <p>
                One of our customers experienced huge scaling problems with a
                collaborative, heavywrite webapp when being hit by over 1500
                concurrent users. Many attempts to improve the situation failed.
                No matter how many servers were started up{' '}
                <strong>
                  the app would become slow even to a point of unresponsiveness.
                  The situation seemed lost.
                </strong>
              </p>

              <p>
                <strong>TheBrain team was called for the rescue!</strong>
              </p>

              <p>
                <strong>In 3 months</strong> we have researched the problem and:
              </p>

              <ul>
                <li>
                  built comprehensive end to end tests around the app (to make
                  sure we are not breaking anything with our performance tweaks)
                </li>
                <li>
                  built a custom loadtest solution for Single Page Applications
                  with load scenarios that reused big parts of the code we wrote
                  for the end to end tests
                </li>
                <li>
                  <strong>
                    decreased the average CPU load with the same number of users
                    by a factor of 10 (!)
                  </strong>
                </li>
                <li>
                  <strong>
                    while cutting the loadtimes of the idle application by half
                  </strong>{' '}
                  (from even 30 to 15 seconds)
                </li>
                <p>
                  All that just in time for the next spike of users. This saved
                  the product, allowed huge savings on infrastructure while
                  making our customers - and their customers - delighted.
                </p>
              </ul>
            </div>
          </CaseStudyDetails>
        </section>
      </Layout>
    )
  }
}

const CaseStudyDetails = styled.div`
  .section {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    max-height: 1000px;
  }
  .section.collapsed {
    transition: max-height 0.5s ease-in-out;
    max-height: 0;
  }
`
export default CaseStudyPage
